import { useStaticQuery, graphql } from "gatsby";

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            productPath
          }
        }
      }
    `
  );
  const { siteMetadata } = site;
  return siteMetadata;
};
