import React from "react";

export default function T1CtaFooter({ links }) {
  return (
    <div id="cta" className="hide-mm py-large">
      {links && Array.isArray(links) && links.length > 0 && (
        <ul className="grid-fixed-1">
          {links.map((link) => (
            <li key={`links-${link.name}`}>
              <a href={link.path} className="capsule-link capsule-link-flex">
                {link.name}
              </a>
            </li>
          ))}
        </ul>
      )}
      {/* <ul className="grid-fixed-1">
        <li>
          <a
            href={tempMailtoString}
            className="enquire-button capsule-link capsule-link-flex"
          >
            Enquire
          </a>
        </li>
      </ul> */}
    </div>
  );
}
