/* eslint-disable react/no-danger */
import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import SubHeaderGeneric from "../components/SubHeaderGeneric";
import DistributionList from "../components/DistributionList";
import FeatureGeneric from "../components/FeatureGeneric";

import ProductDimensionsImage from "../components/ProductDimensionsImage";
import SeoImageSrc from "../components/SeoImageSrc";
import T1CtaFooter from "../components/T1CtaFooter";

export default function T1Default({ data, pageContext }) {
  // clg(data.T1);
  // console.log("webDP in component:", pageContext.webDP);
  const T1 = data.T1.data;
  // const T2 = data.T2.data;
  // const productImages = data.productImages.nodes;
  const { productPath } = useSiteMetadata();
  // const allDimensionsDiagrams = data.allDimensionsDiagrams.nodes;

  /* Specification Sheet */

  // What would the ideal sheet name be?

  // const specificationSheet = allSpecificationSheets.filter(
  //   (sheet) =>
  //     sheet.name ===
  //     `${T1.webT2DisplayNameFirst} ${T1.webModelCode} (Specification)`
  // );
  // TODO Continued in subheaderLink below
  // if (Array.isArray(specificationSheet) && specificationSheet.length > 0) {
  //   clg(
  //     `Found spec sheet ${specificationSheet[0].name}${specificationSheet[0].ext}`
  //   );
  //   clg(specificationSheet);
  // } else {
  //   clg("No spec sheet found");
  // }

  // const { T2 } = data;
  // const { T3 } = data;
  // const { T4 } = data;

  // const modelCode = T1.data.Model_Code;

  // let heroImage;
  // const secondaryImages = [];

  // if (T1.data.Hero_Image) {
  //   const heroImageData = getProductImageData(
  //     T1.data.Hero_Image[0].data.Filename,
  //     productImages
  //   );
  //   if (heroImageData) heroImage = getImage(heroImageData);
  // }

  // if (T1.data.Secondary_Images) {
  //   T1.data.Secondary_Images.forEach((imageData) => {
  //     const image = getProductImageData(imageData.data.Filename, productImages);
  //     if (image) secondaryImages.push(getImage(image));
  //   });
  // }

  let tempProductName = T1.webModelCode;
  if (T1.webT2DisplayNameSecond)
    tempProductName = `${T1.webT2DisplayNameSecond} ${tempProductName}`;
  if (T1.webT2DisplayNameFirst)
    tempProductName = `${T1.webT2DisplayNameFirst} ${tempProductName}`;

  // const T2Fullname = `${T1.webT2DisplayNameFirst} ${T1.webT2DisplayNameSecond}`;
  const T2Path = `${productPath}/${T1.webT4Slug}/${T1.webT2Slug}`;

  /* TODO Tidy mailto and categories for T1 */
  const tempMailtoString = `mailto:general@ratio.lighting?subject=Enquiry: ${tempProductName}&body=${tempProductName}`;
  const subheaderLink = [];

  const specificationSheetArray = data.allSpecificationSheets.nodes;

  // clg({ specificationSheetArray });
  if (
    Array.isArray(specificationSheetArray) &&
    specificationSheetArray.length > 0
  ) {
    // clg(specificationSheetArray[0]);
    const specificationSheet = specificationSheetArray[0];
    subheaderLink.push({
      name: "Specification Sheet",
      path: specificationSheet.publicURL,
      download: `${specificationSheet.name}${specificationSheet.ext}`,
    });
  } else {
    // clg("No spec sheet found");
  }

  const allProductPhotometry = data.allProductPhotometry.nodes;
  // console.log(allProductPhotometry);
  // If photometry is specified in data
  if (T1.webDP) {
    const { webDP } = T1;

    // if we can find the photometry file
    const photometryFileData = allProductPhotometry.find(
      (x) => `${x.name}${x.ext}` === webDP
    );
    if (photometryFileData)
      subheaderLink.push({
        name: "Photometry",
        path: photometryFileData.publicURL,
        download: `${photometryFileData.name}${photometryFileData.ext}`,
      });
  }

  subheaderLink.push({ name: "Enquire", path: tempMailtoString });

  /* Hero Image */
  let heroImageFilename = "";
  // Is there a T1 image
  if (
    T1.webHeroImage &&
    T1.webHeroImage.length > 0 &&
    T1.webHeroImage[0].data.Filename
  ) {
    // Use the T1 image
    heroImageFilename = T1.webHeroImage[0].data.Filename;
  } else if (
    T1.webT2HeroImage &&
    T1.webT2HeroImage.length > 0 &&
    T1.webT2HeroImage[0].data.Filename
  ) {
    heroImageFilename = T1.webT2HeroImage[0].data.Filename;
  }
  // const seoImageSrc = getSeoImageSrc(heroImageFilename);
  const seoImageSrc = SeoImageSrc(heroImageFilename);

  // const seoImageSrc = getSeoImageSrc(heroImageFilename, productImages);

  return (
    <>
      <Helmet>
        <title>{tempProductName} — Ratio Lighting</title>
        <meta property="og:image" content={seoImageSrc && seoImageSrc} />
        <meta property="og:title" content={tempProductName} key="ogtitle" />
        {tempProductName && (
          <meta
            name="description"
            content={`Product details for ${tempProductName}`}
          />
        )}
        {tempProductName && (
          <meta
            property="og:description"
            content={`Product details for ${tempProductName}`}
            key="ogdesc"
          />
        )}
      </Helmet>
      <main>
        <SubHeaderGeneric
          productName={[
            { name: T1.webT2DisplayNameFirst, path: T2Path },
            { name: T1.webT2DisplayNameSecond, path: T2Path },
            { name: T1.webModelCode },
          ]}
          links={subheaderLink}
          // breadcrumb={[{ name: T2Fullname, path: T2Path }]}
        />
        <div id="grid-t1-a" className="px-medium mb-xxl mb-ml-medium">
          {/* Added padding because of configurator-result-container */}
          <div id="feature-image" className="pb-large pb-ml-small">
            {heroImageFilename && (
              <FeatureGeneric
                filename={heroImageFilename}
                alt={tempProductName}
                // productImages={productImages}
              />
            )}
            {/* Hiding because of configurator-result-container */}
            {/* <hr className="hide-ml mt-large mt-ml-small mb-medium mb-ml-small" /> */}
          </div>
          <div
            id="attributes"
            className="configurator-result-container mb-large mb-ml-small fs-small fs-mm-medium fs-mxl-medium fs-mxxl-large fs-mxxxl-xl"
          >
            {(T1.webT2DetailCopy && T1.webT2DetailCopy.childMarkdownRemark) ||
            (T1.webT2FunctionCopy &&
              T1.webT2FunctionCopy.childMarkdownRemark) ? (
              <>
                <dl className="flex-2-grid">
                  {T1.webT2DetailCopy &&
                    T1.webT2DetailCopy.childMarkdownRemark && (
                      <>
                        <dt className="column">Detail</dt>
                        <dd
                          className="column"
                          dangerouslySetInnerHTML={{
                            __html: T1.webT2DetailCopy.childMarkdownRemark.html
                              .replace('["', "")
                              .replace('"]', ""),
                          }}
                        />
                      </>
                    )}
                  {T1.webT2FunctionCopy &&
                    T1.webT2FunctionCopy.childMarkdownRemark && (
                      <>
                        <dt className="column">Function</dt>
                        <dd
                          className="column"
                          dangerouslySetInnerHTML={{
                            __html:
                              T1.webT2FunctionCopy.childMarkdownRemark.html
                                .replace('["', "")
                                .replace('"]', ""),
                          }}
                        />
                      </>
                    )}
                </dl>
                <hr className="my-medium my-ml-small" />
              </>
            ) : null}
            <dl className="flex-2-grid">
              {T1.webPower && (
                <>
                  <dt className="column">System Power</dt>
                  <dd className="column">
                    <ul>{T1.webPower}W</ul>
                  </dd>
                </>
              )}
              {T1.pbVAC && (
                <>
                  <dt className="column">Voltage</dt>
                  <dd className="column">
                    <ul>AC {T1.pbVAC}V</ul>
                  </dd>
                </>
              )}
              {T1.pbVDC && (
                <>
                  <dt className="column">Voltage</dt>
                  <dd className="column">
                    <ul>DC {T1.pbVDC}V</ul>
                  </dd>
                </>
              )}
              {T1.webFlux && (
                <>
                  <dt className="column">Luminous Flux</dt>
                  <dd className="column">
                    <ul>{T1.webFlux}lm</ul>
                  </dd>
                </>
              )}
              {T1.webColourTemperature && (
                <>
                  <dt className="column">Colour Temperature</dt>
                  <dd className="column">
                    <ul>
                      {T1.webColourTemperature}
                      {["RGB", "RGBW", "RGBTW"].includes(
                        T1.webColourTemperature
                      )
                        ? ""
                        : "K"}
                    </ul>
                  </dd>
                </>
              )}
              {T1.webCRI && (
                <>
                  <dt className="column">CRI</dt>
                  <dd className="column">Ra{T1.webCRI}</dd>
                </>
              )}
              {T1.wTM30RF && (
                <>
                  <dt className="column">TM-30 RH</dt>
                  <dd className="column">{T1.wTM30RF}</dd>
                </>
              )}
              {T1.wTM30RG && (
                <>
                  <dt className="column">TM-30 RG</dt>
                  <dd className="column">{T1.wTM30RG}</dd>
                </>
              )}
              {T1.wLM && (
                <>
                  <dt className="column">Lumen Maintenance</dt>
                  <dd className="column">{T1.wLM}</dd>
                </>
              )}
            </dl>
            {T1.webT2OpticalAssemblyCopy ||
            T1.webT2DistributionType ||
            T1.webDistribution ? (
              <>
                <hr className="my-medium my-ml-small" />
                <dl className="flex-2-grid">
                  {T1.webT2OpticalAssemblyCopy || T1.webT2DistributionType ? (
                    <>
                      <dt className="column">Optical Assembly</dt>
                      <dd className="column">
                        <ul>
                          {T1.webT2OpticalAssemblyCopy && (
                            <li>{T1.webT2OpticalAssemblyCopy}</li>
                          )}
                          {T1.webT2DistributionType && (
                            <li>{T1.webT2DistributionType}</li>
                          )}
                        </ul>
                      </dd>
                    </>
                  ) : null}
                  {T1.webDistribution && (
                    <>
                      <dt className="column">Distribution</dt>
                      <dd className="column">
                        <ul>
                          {/* <AttributeList
                      values={T2.webDistribution}
                      prefix="Distribution"
                    /> */}
                          <DistributionList
                            distributions={[T1.webDistribution]}
                          />
                        </ul>
                      </dd>
                    </>
                  )}
                </dl>
              </>
            ) : null}
            {T1.webIPRating || T1.webIKRating || T1.webCO || T1.webRD ? (
              <>
                <hr className="my-medium my-ml-small" />
                <dl className="flex-2-grid">
                  {T1.webIPRating && (
                    <>
                      <dt className="column">IP Rating</dt>
                      <dd className="column">IP{T1.webIPRating}</dd>
                    </>
                  )}
                  {T1.webIKRating && (
                    <>
                      <dt className="column">IK Rating</dt>
                      <dd className="column">IK{T1.webIKRating}</dd>
                    </>
                  )}
                  {T1.webCO && (
                    <>
                      <dt className="column">Cut Out</dt>
                      <dd className="column">{T1.webCO}</dd>
                    </>
                  )}
                  {T1.webRD && (
                    <>
                      <dt className="column">Recess Depth</dt>
                      <dd className="column">{T1.webRD}</dd>
                    </>
                  )}
                </dl>
              </>
            ) : null}
            {T1.webFinish || T1.wMCN ? (
              <>
                <hr className="my-medium my-ml-small" />
                <dl className="flex-2-grid">
                  {T1.webFinish && (
                    <>
                      <dt className="column">Finish</dt>
                      <dd className="column">{T1.webFinish}</dd>
                    </>
                  )}
                  {T1.wMCN && (
                    <>
                      <dt className="column">Mounting</dt>
                      <dd className="column">{T1.wMCN}</dd>
                    </>
                  )}
                </dl>
              </>
            ) : null}
            {T1.webControlProtocolRemote || T1.webControlProtocolIntegrated ? (
              <>
                <hr className="my-medium my-ml-small" />
                <dl className="flex-2-grid">
                  <dt className="column">Control</dt>
                  <dd className="column">
                    <dl>
                      <dt className="fs-tiny fw-medium">
                        {T1.webControlProtocolRemote ? "Remote" : "Integrated"}
                      </dt>
                      <dd>
                        {T1.webControlProtocolRemote
                          ? T1.webControlProtocolRemote
                          : T1.webControlProtocolIntegrated}
                      </dd>
                    </dl>
                    <aside className="fs-tiny c-fade">Driver Included</aside>
                  </dd>
                </dl>
              </>
            ) : null}

            {/* If Protocol is CE then simply display Driver Location */}

            {T1.wbCE === "CE" && T1.wbDriverLocation ? (
              <>
                <hr className="my-medium my-ml-small" />
                <dl className="flex-2-grid">
                  <dt className="column">Control</dt>
                  <dd className="column">
                    {T1.wbDriverLocation}
                    <aside className="fs-tiny c-fade">
                      Driver not included
                    </aside>
                  </dd>
                </dl>
              </>
            ) : null}
          </div>

          {T1.webT2DimensionsDiagrams &&
          Array.isArray(T1.webT2DimensionsDiagrams) ? (
            <div id="dimensions-diagrams" className="">
              <section className="mb-medium">
                <ProductDimensionsImage
                  // data={T2}
                  diagramsString={T1.webT2DimensionsDiagrams[0]}
                  // diagramsData={allDimensionsDiagrams}
                />
              </section>
              <hr className="hide-mm mb-medium" />
            </div>
          ) : null}

          <T1CtaFooter links={subheaderLink} />
        </div>
      </main>
    </>
  );
}

export const query = graphql`
  query ($T1RecordId: String!, $webSpecificationSheet: String, $webDP: String) {
    productSlug: site {
      siteMetadata {
        productPath
      }
    }
    T1: airtableProductsT1(recordId: { eq: $T1RecordId }) {
      recordId
      data {
        wbDriverLocation
        wbProtocol
        pbVAC
        pbVDC
        wTM30RF
        wTM30RG
        wMCN
        webCO
        webColourTemperature
        webControlProtocol
        webControlProtocolIntegrated
        webControlProtocolRemote
        webCRI
        webDistribution
        webFinish
        webFlux
        webHeroImage {
          data {
            Filename
          }
        }
        webIPRating
        webIKRating
        wLM
        webModelCode
        webDP
        webOpticalAccessory
        webPower
        webRD
        webSlug
        webT2DimensionsDiagrams
        webT2DisplayNameFirst
        webT2DisplayNameSecond
        webT2DistributionType
        webT2DetailCopy {
          childMarkdownRemark {
            html
          }
        }
        webT2FunctionCopy {
          childMarkdownRemark {
            html
          }
        }
        webT2HeroImage {
          data {
            Filename
          }
        }
        webT2OpticalAssemblyCopy
        webT2Slug
        webT4Slug
      }
    }
    allProductPhotometry: allFile(
      filter: { sourceInstanceName: { eq: "photometry" }, name: { eq: $webDP } }
    ) {
      nodes {
        publicURL
        sourceInstanceName
        size
        relativeDirectory
        ext
        name
      }
    }
    allSpecificationSheets: allFile(
      filter: { name: { eq: $webSpecificationSheet } }
    ) {
      nodes {
        publicURL
        sourceInstanceName
        ext
        name
      }
    }
  }
`;
